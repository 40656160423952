import React, { useEffect, useState } from "react"
import Section from "../components/blocks/Section/Section"
import TitreBlock from "../components/blocks/TitreBlock/TitreBlock"
import Layout from "../components/layouts/Layout/layout"
// import Banner from "../components/sections/Banner/Banner"
import { navigate } from "gatsby"
import RgpdPopUp from "../components/sections/RgpdPopUpSection/RgpdPopUp"
import Seo from "../components/seo"
import {
  clearAccessToken,
  clearPersonne,
  getPersonne,
  isLoggedIn,
  isLoggedInAndConfirmed,
  isLoggedInAndConfirmedCgu,
  logout,
  setAccessToken,
  setPersonne,
  updateRgpd,
} from "../services/auth"
import componentFactory from "../utils/componentFactory"
import "./page-template.scss"

const PageTemplate = ({ pageContext }) => {
  const page = pageContext.page.attributes
  const components = page.contenu.map(i => componentFactory(i, pageContext))
  const bgImg = page.url === "/" && false
  const background = page.url === "/" && ""
  const className =
    page.url === "/"
      ? "faltitre_page_statiquese"
      : "titre_page_statique"
  const [cgu, setCgu] = React.useState(true)
  const [charte, setCharte] = React.useState(true)
  const [rgpdOpen, setRgpdOpen] = useState(false)
  const [rgpdError, setRgpdError] = useState("")

  const handleCguChange = event => {
    setCgu(event.target.checked)
  }
  const handleCharteChange = event => {
    setCharte(event.target.checked)
  }
  const isLoggedInAndConfirm = isLoggedInAndConfirmed()
  const isLoggedInCgu = isLoggedInAndConfirmedCgu()

  useEffect(() => {
    const checkLoginStatus = () => {
      if (isLoggedInAndConfirm && !isLoggedInCgu) {
        setRgpdOpen(true)
        console.log("still true")
      } else if (isLoggedIn()) {

      } else {
        return
      }
      // else {
      //   navigate("/mon-espace/priv/formations")
      //   console.log("navagate")
      // }
    }

    checkLoginStatus()
  }, [isLoggedInAndConfirm, isLoggedInCgu, isLoggedIn, navigate])

  const handleRgpdClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log("backdropClicked. Not closing dialog.")
    }
  }

  const handleCloseRgpdPopUp = () => {
    logout(() => {})
    setRgpdOpen(false)
    // if (!isLoggedIn()) navigate("/mon-espace/login/")
  }

  const person = getPersonne()
  const rgpdConfirmationFromHome = async () => {
    try {
      const response = await updateRgpd({ id: person.id, cgu, charte })
      console.log(response)
      console.log("personne before", person)
      clearAccessToken()
      clearPersonne()
      setAccessToken({
        accessToken: response.accessToken,
        isPermanent: true,
      })

      setPersonne({ personne: response.personne, isPermanent: true })
      setRgpdOpen(false)
    } catch (err) {
      setRgpdError(err.response?.data?.message)
      console.log(err)
    }
  }

  return (
    <>
      <Seo
        description={page.metaDescription}
        title={page.titre}
        lang="fr"
      ></Seo>
      <Layout chemin={page.chemin}>
        {page.titre && (
          <Section className={className} bgImg={bgImg} background={background}>
            <TitreBlock
              title={page.titre}
              baseline={page.sousTitre}
              level="h1"
            ></TitreBlock>
          </Section>
        )}
        {/* {page.baniere.data && (
          <Banner
            image={page.baniere.data?.attributes.url}
            alt={page.baniere.data?.attributes.alternativeText}
          />
        )} */}
        {rgpdOpen && (
          <RgpdPopUp
            isOpen={rgpdOpen}
            handleClose={handleRgpdClose}
            handleCloseRgpdPopUp={handleCloseRgpdPopUp}
            handleCguChange={handleCguChange}
            handleCharteChange={handleCharteChange}
            cgu={cgu}
            setCgu={setCgu}
            charte={charte}
            setCharte={setCharte}
            rgpdConfirmation={rgpdConfirmationFromHome}
            rgpdError={rgpdError}
            setRgpdError={setRgpdError}
          />
        )}
        {components}
      </Layout>
    </>
  )
}

export default PageTemplate
